<template>
  <div class="authentication-bg">
    <div class="bg-overlay">
    </div>

    <div class="ecat-overlay">
      <div class="ecat-overlay-title text-center text-white w-50 font-weight-bold">
        <div v-html="$t('information-panel.title')" />
      </div>
      <div class="ecat-overlay-subtitle w-50 text-center">
        {{  $t('information-panel.subtitle') }}
      </div>
      <div class="ecat-overlay-laptop">
        <img src="../../assets/images/laptop.svg" alt="laptop">

        <div class="iframe-container">
          <help-video-render width="570px" height="360px"/>
        </div>
      </div>
      <div class="ecat-overlay-footer d-inline-flex px-4 justify-content-between w-50">
        <div class="d-flex flex-column justify-content-center">
          <div class="text-white">
            {{ $t("information-panel.download-app.title") }}
          </div>
          {{ $t("information-panel.download-app.subtitle") }}
        </div>
        <div class="d-inline-flex">
          <img src="../../assets/images/google-play.svg" alt="Google Play">
          <img src="../../assets/images/app-store.svg" alt="App Store" class="pl-1">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelpVideoRender from "@/components/help-video/help-video-render.vue";

export default {
  name: "information-panel-render",
  components: {HelpVideoRender},

  data() {
    return {
      loading: false
    }
  },

  mounted() {
    this.$root.$on("change-language");
  },

  beforeDestroy() {
    this.$root.$off("change-language");
  }
}
</script>