<script>
import {required, minLength, sameAs} from "vuelidate/lib/validators";
import FlagButton from '@/components/FlagButton.vue';
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {routerHelper} from "@/helpers/router-helper";
import Swal from "sweetalert2";
import {logoHelper} from "@/helpers/logo-helper";
import InformationPanelRender from "@/components/account/information-panel-render.vue";

export default {
  components: {InformationPanelRender, FlagButton},
  data() {
    return {
      newPassword: "",
      confirmPassword: "",

      submitted: false,
      identifier: ""
    };
  },

  validations: {
    newPassword: {
      required,
      minLength: minLength(8),
      containsUppercase: function (value) {
        return /[A-Z]/.test(value)
      },
      containsLowercase: function (value) {
        return /[a-z]/.test(value)
      },
      containsNumber: function (value) {
        return /[0-9]/.test(value)
      },
      containsSpecial: function (value) {
        return /\W/.test(value)
      }
    },

    confirmPassword: { required, sameAsPassword: sameAs("newPassword") },
  },

  created() {
    document.body.classList.add("auth-body-bg");

    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());

    if (!urlParams.identifier) {
      routerHelper.push("/login")
      return;
    }

    this.identifier = urlParams.identifier
  },

  methods: {
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const json = JSON.stringify({
        "resetId": this.identifier,
        "newPassword": this.newPassword
      });

      axios.post(`/user/forgot-password/change`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        this.$router.push('/login').then(() => {
          Swal.fire("Sukces!", "Hasło do konta zostało zmienione!", "success");
        }).catch((error) => {
          if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
            console.log(error)
          }
        })
        this.submitted = false;
      }).catch((error) => {
        this.submitted = false;
        errorCatcher.catchErrors(error);
      })
    }
  },

  computed: {
    logoHelper() {
      return logoHelper
    },
    getImageSize() {
      let screenWidth = screen.width;
      let width = 60;
      if (screenWidth > 576 && screenWidth <= 992) {
        width = 35;
      }

      return width;
    }
  },

};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <FlagButton :drop-left="true"></FlagButton>
      <button @click="$store.dispatch('theme/switchTheme').then(() => $store.dispatch('theme/updateTheme'))" type="button" class="btn btn-sm px-3 font-size-24 header-item">
        <i :class="$store.getters['theme/isThemeDark'] ? 'ri ri-sun-line' : 'ri ri-moon-line'" style="color: #ffffff;"></i>
      </button>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img :src="logoHelper.logo()" :height="getImageSize" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">{{ $t('forgot-password.title')}}</h4>
                        <p class="text-muted">{{ $t('forgot-password.subtitle') }}</p>
                      </div>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="tryToReset">
                          <div class="form-group auth-form-group-custom mb-2">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="password">{{  $t('message.new-password') }}</label>
                            <input
                                v-model="newPassword"
                                type="password"
                                class="form-control"
                                id="password"
                                :placeholder="$t('message.type', { 'type': $t('message.new-password').toLowerCase()})"
                                :class="{ 'is-invalid': submitted && $v.newPassword.$error }"/>
                            <div v-if="submitted && !$v.newPassword.required" class="invalid-feedback">{{ $t('message.required')}}</div>
                            <div v-if="submitted && !$v.newPassword.minLength" class="invalid-feedback">{{ $t('register.password-min-length')}}</div>
                            <div v-if="submitted && !$v.newPassword.containsUppercase" class="invalid-feedback">{{ $t('register.password-uppercase')}}</div>
                            <div v-if="submitted && !$v.newPassword.containsLowercase" class="invalid-feedback">{{ $t('register.password-lowercase')}}</div>
                            <div v-if="submitted && !$v.newPassword.containsNumber" class="invalid-feedback">{{ $t('register.password-numeric')}}</div>
                            <div v-if="submitted && !$v.newPassword.containsSpecial" class="invalid-feedback">{{ $t('register.password-special')}}</div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-2">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="confirmPassword">{{ $t('register.repeat-new-password') }}</label>
                            <input
                                v-model="confirmPassword"
                                type="password"
                                class="form-control"
                                id="confirmPassword"
                                :placeholder="$t('message.type', { 'type': $t('message.new-password').toLowerCase()})"
                                :class="{ 'is-invalid': submitted && $v.confirmPassword.$error }"/>
                            <div v-if="submitted && !$v.confirmPassword.required" class="invalid-feedback">{{ $t('message.required')}}</div>
                            <div v-if="submitted && !$v.confirmPassword.sameAsPassword && $v.confirmPassword.required" class="invalid-feedback">{{ $t('register.samePassword')}}</div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >{{  $t('forgot-password.reset') }}</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <information-panel-render/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>